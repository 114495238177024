/* ************ SERVICES *********** */

.header-data {
  font-weight: 700;
  padding: 0 10px;
  color: black;
  border-right: 2px solid black;
  cursor: pointer;
}
#servicesCard .card ::before{
      content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: #F46A25;
    width: 100%;
    height: 2px;
  }
  .mainCard{
  transition: .3s;
  cursor: pointer;
}
.mainCard:hover {
  transform: translateY(-10px);
}
.bg-table {
  --bs-bg-opacity: 1;
  background-color:#F46A25 !important;
}
.download {
  outline: 3px solid white;
        color: white;
        /* font-weight: bold !important; */
         border-radius: 30px;
    background: #F46A25;
    background: -moz-linear-gradient(top, #F46A25 0%, #F46A25 100%);
    background: -webkit-linear-gradient(top, #F46A25 0%, #F46A25 100%);
    background: linear-gradient(to bottom, #F46A25 0%, #F46A25 100%);
        -webkit-transition: 1.1s;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#df3127',endColorstr='#ba2218',GradientType=0);

}
.download:hover{
    background: white !important;
    outline-color: #F46A25;
    color:  #F46A25;
}

.nav-pills li,.tab{
        box-shadow: 0 0 16px rgba(0,0,0,.1);
    }
    
    .nav-pills .nav-link.active{
        width: 100%;
    border-bottom: 2px solid #F46A25;
    color: #F46A25 !important;
    background-color: transparent;
    border-radius: unset;
}

.nav-pills .nav-link.active {
  width: 100%;
  border-bottom: 2px solid #F46A25;
  color: #F46A25 !important;
  background-color: transparent;
  border-radius: unset;
}

/* ************** slider  ********************/

.serviceProvide {
  box-shadow: 3px 0 40px rgba(0, 0, 0, 0.19);
  border-radius: 10px;
}
.serviceProvide .owl-theme .owl-nav {
  color: #9f9f9f;
}
.serviceProvide .owl-theme .owl-nav .owl-prev {
  font-size: 32px;
  position: absolute;
  padding: 10px;
  left: -40px;
  top: 14px;
}
.serviceProvide .owl-theme .owl-nav .owl-next {
  font-size: 32px;
  padding: 10px;
  position: absolute;
  right: -40px;
  top: 14px;
}
.serviceProvide .owl-theme .owl-nav [class*="owl-"] {
  background-color: unset;
  color: unset;
}
/* .owl-theme .owl-nav [class*="owl-"] {
  background-color: unset !important;
  color: unset !important;
} */

/* ******* services grid ********** */
.newsBoxImg img {
  filter: grayscale(100%);
  overflow: hidden;
  transition: 0.4s;
}
.description {
  color: white;
  left: 0;
  bottom: -55px;
  transition: 0.4s;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0,
    rgba(0, 0, 0, 0.85) 40%,
    rgba(0, 0, 0, 0.85) 60%
  );
}
.newsBox:hover .description {
  bottom: 0px;
}
.newsBox:hover .newsBoxImg img {
  filter: unset;
}

.accordion-button:not(.collapsed) {
  color: unset !important;
  background-color: unset !important;
  box-shadow: unset !important;
  outline: 0 !important;
}
.accordion-button:focus {
  box-shadow: none;
}

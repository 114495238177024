/* *********** FOOTER ************** */

.footer-data,.footer-not{
  font-size: 12px;
  font-weight: 500;
  color: white;
  transition: .3s;
  cursor: pointer;
  margin: 5px 0;
}
.footer-data:hover{
  color:#F46A25;
}
.footer-icon{
  height: 34px;
  width: 34px;
  display: flex;
  justify-content: center;align-items: center;
  border-radius: 30px;
  color: #3F3F3F;
  background-color: white;
  transition: .4s;
  cursor: pointer;
}
.footer-icon:hover{
  background-color: #3F3F3F;
  outline: 2px solid white;
  color: white;
}
.footer-bg{
  background-color: #3F3F3F;
  padding-bottom: 20px;
}
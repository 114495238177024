
/* ***font-size***** */

.fs-11{
    font-size: 11px;
}
.fs-12{
    font-size: 12px;
}
.fs-13{
    font-size: 13px;
}
.fs-14{
    font-size: 14px;
}
.fs-15{
    font-size: 15px;
}
.fs-14 {
  font-size: 14px;
}
.fs-17 {
  font-size: 17px;
}
.fs-18 {
  font-size: 18px;
}
.fs-20 {
  font-size: 20px;
}
.fs-22 {
  font-size: 22px;
}
.fs-23 {
  font-size: 23px;
}
.fs-24 {
  font-size: 24px;
}
.fs-33 {
  font-size: 33px;
}
.fs-43 {
  font-size: 43px;
}
/* ********color*** */
.black {
  color: #121212;
}
.lightBlack {
  color: #1C0102;
}
.red {
  color: #F46A25 !important;
}
.white {
  color: #fff;
}
.lightGray {
  color: #525252;
}
.gray {
  color: #666666;
}
/* ***outline*** */
.outline-none{
    outline: none;
}
/* *****gold calc**** */
.otpGenerateBtn{
    padding: 8px 16px;
    background: -webkit-linear-gradient(top, #F46A25 0, #F46A25 100%);
    color: white;
    border-radius: 20px;
    border: 2px solid #F46A25;
    transition: 0.3s;
}
.otpGenerateBtn:hover{
  background: white;
  color: #F46A25;
  border: 2px solid #F46A25;
}
/* ==============subscribe component========== */

.subscribe {
  width: 600px;
  background-color: #F46A25;
  color: pink;
  display: flex;
  border: 1px solid #F46A25;
  margin: 0 auto;
  border-radius: 5px;
  overflow: hidden;
}

@media only screen and (max-width: 992px) {
  .subscribe {
    width: 100%;
  }
}

.subscribeInput {
  flex-grow: 1;
  border: none;
  padding: 10px;
}

.subscribeInput:focus {
  border: none;
  outline: none;
}

.subscribeBtn {
  border-radius: none;
  color: #fff;
}

.subscribeBtn:hover {
  color: #fff;
}

.subscribeBtn:active {
  border: none;
  outline: none;
}

/* =========global hero========= */

.word:hover {
  color: #d71920;
  transition: 0.2s;
}
.mainHero {
  width: 100%;
}
.heroimage {
  /* height: 450px; */
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.background {
  background-color: #F46A25;
  padding: 5px;
}
.fixBox {
  display: inline-block;
}
.imagedetails {
  padding: 155px 0px;
}
@media screen and (max-width: 768px) {
  .heroimage {
    background-position: right;
  }
}
/* **********spacing********* */
.top-space{
  margin-top: 80px;
}
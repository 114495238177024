/*  *********** HEADER ************* */

.header-hover{
  font-size: 14px;
  font-weight: 500;
  transition: 0.3s;
}
.bg-orange{
  background-color: #FA7B31;
}
.text-orange{
  color: #FA7B31;
}
.navbar-toggler:focus {
    box-shadow:unset;
}
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Montserrat", sans-serif;
}

html {
  scroll-behavior: smooth;
}

li {
  list-style-type: none;
}

a {
  text-decoration: none;
  padding: 0 !important;
  color: unset;
}

img {
  height: 100%;
  width: 100%;
  max-height: 100%;
  object-fit: cover;
}

ul {
  margin: 0;
  padding: 0;
}
p {
  margin: 0;
  padding: 0;
}
.text-danger {
  color: #f46a25 !important;
}
.accordionhead {
  padding: 20px 40px 20px 0px;
}
.accordionBox {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
  border: 1px solid #dadada;
}
/* *************schemes********* */
.schemesBox {
  border: 2px solid #d7d7d7;
  transition: 0.4s;
  background: linear-gradient(225deg, #ff7800d6 45%, transparent);
  border-radius: 10px;
}
.schemesBox .schemeBoxTitle {
  transition: 0.4s;
}
.learnMore:hover {
  text-decoration: underline;
  cursor: pointer;
}
/* *********modal******** */

.modal-dialog {
  max-width: 650px;
}
.schemesBoxs{
cursor: pointer;
transition: .4s;
}
.schemesBoxs:hover{
  transform: translateY(-8px);
}

.per .btn-close {
  --bs-btn-close-bg: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e);
  --bs-btn-close-opacity: 1;
    --bs-btn-close-hover-opacity: 1;
    --bs-btn-close-focus-shadow: unset;
    --bs-btn-close-focus-opacity: 1;
    --bs-btn-close-disabled-opacity: 0.25;
   
}
.closeButton{
  height: 30px;
  width: 30px;
  background-color: #FA7B31;
  border-radius: 50%;
  font-size: 15px;
 

}
/* @media screen and (max-width: 600px) {
  .modal-dialog {
    width: 100% !important;
  }
} */

/*
@media screen and (max-width: 992px) {
  .modal-dialog {
    max-width: 50%;
  }
} 
*/
